.modalData {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.goBackWhite {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: ;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.40), rgba(252, 252, 252, 0.55), rgba(252, 252, 252, 0.70), rgba(252, 252, 252, 0.80), rgba(245, 245, 245, 0.95));
  cursor: pointer;
  border: 0px;
}

.data_container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  justify-content: center;
  background-color: #172F58;
  color: white;
  padding: 25px 30px;
  width: 400px;
}

.partnerLink {
  color: #FFFFFF;
  transition: color 0.05s ease-in-out;
}

.partnerLink:hover {
  color: #FFCB05;
}

.econHeader {
  margin-top: 16px;
  margin-bottom: 0px;
}

.close_button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  font-size: 21px;
  border-bottom-left-radius: 5px;
  background: #FFCB05;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 60em) {
  .modalData {
    width: 100%;
    margin-left: 0px;
  }

  .data_container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 500px) {
  .modalData {
    justify-content: center;
  }
  .data_container {
    border-radius: 10px;
  }
}


