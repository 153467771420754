.map_container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.iframe {
  border: 0px;
}
