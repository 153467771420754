.insights_container {
  display: flex;
  margin: 20px 40px;
}

.insights_list {
  flex: 0 0 25%;
  background-color: #e4e4e4;
  color: #666;
  padding: 30px 30px;
  margin: 10px 30px 10px 10px;
  border-radius: 10px;
  height: fit-content;
  max-height: 75vh;
  overflow-y: scroll;
}

.insights_list div {
  cursor: pointer;
  transition: font-weight 50ms ease-in;
}

.insights_list div:hover {
  font-weight: bold;
}

.insights_list div:active {
  color: #172F58;
}

.insight_title {
  margin-bottom: 5px;
}

.insight_title_active {
  color: #172F58;
  font-weight: bold;
}

.insights_content {
  flex: 0 0 calc(75% - 40px);
}

.title_separator {
  width: 175px;
  height: 7px;
  background: #2484c6;
  transform: skewX(25deg);
  margin-top: 10px;
  margin-bottom: 10px;
}

.landImage {
  width: 80%;
}

.square {
  width: 45%;
}

.portrait {
  width: 25%;
}

.select_container {
  display: none;
}

@media screen and (max-width: 75em) {
  .insights_container {
    margin: 20px 0px;
  }
}

@media screen and (max-width: 50em) {
  .insights_list {
    display: none;
  }
  .insights_content {
    flex-basis: 100%;
  }
  .select_container {
    display: block;
    margin-bottom: -20px;
  }
}
