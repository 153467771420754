.modalData {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goBackWhite {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.80);
  cursor: pointer;
}

.data_container {
  max-width: 80ch;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  background-color: #172F58;
  color: white;
  padding: 25px 30px;
}

.partnerLink {
  color: #FFFFFF;
  transition: color 0.05s ease-in-out;
}

.partnerLink:hover {
  color: #FFCB05;
}

.commRHeader {
  margin-top: 16px;
  
}

.close_button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  font-size: 21px;
  border-bottom-left-radius: 5px;
  background: #FFCB05;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


