.margined_content {
  margin: 30px 100px;
}

.content > p {
  font-size: 16px;
  margin: 20px 0px;
}

.content > p > b {
  font-size: 17px;
  padding: 20px 0px;
}

.content img {
  width: 55%;
  height: 100%;
  margin: 30px 0px;
  border-radius: 10px;
  margin-left: 27.5%;
}

.content a {
  color: black;
  font-weight: 500;
}

.yt_centered {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.yt_contain {
  width: 90%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
}

@media only screen and (max-width: 70em) {
  .margined_content {
    margin: 30px 80px;
  }
  .content img {
    width: 65%;
    height: 100%;
    margin: 30px 0px;
    border-radius: 10px;
    margin-left: 17.5%;
  }
}

@media only screen and (max-width: 60em) {
  .margined_content {
    margin: 30px 60px;
  }
  .yt_contain {
    width: 100%;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 45em) {
  .margined_content {
    margin: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .content img {
    width: 100%;
    height: 100%;
    margin: 30px 0px;
    border-radius: 10px;
    margin-left: 0%;
  }
}