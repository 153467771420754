.flex_contain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo {
  width: 100%;
}
