.microsite_card_contain {
  display:flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin: 25px 0px 15px 0px;
}

.microsite_card {
  flex: 1 0 0;
  margin: 5px 5px;
  min-height: 350px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.absolute_background {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  transition: transform .25s ease-in;
}

.microsite_card:hover > .absolute_background {
  transform: scale(1.02);
}

.desc_microsite {
  border-radius: 10px;
  text-align: center;
}

.desc_microsite h3 {
  font-size: 1.3rem;
  margin: 15px 0px;
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 3px 4px;
  transition: color .275s ease-in;
}

.microsite_card:hover > .desc_microsite h3 {
  color: white;
}


.desc_microsite > h3::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #2484c6;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.275s ease-in;
}

.microsite_card:hover > .desc_microsite h3::after {
  transform-origin: left;
  transform: scaleX(1);
}


.desc_microsite p {
  position: relative;
  font-size: 0.9rem;
  max-width: 25ch;
  margin: 0 auto;
  padding-bottom: 20px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.275s ease-in;
}


.microsite_card:hover > .desc_microsite p {
  opacity: 1;
}

.desc_DGI {
  background-color: #001C4ECC;
  color: white;
  position: relative;
  z-index: 2;
}

.desc_BFG {
  background-color: #BABB1FCC;
  color: #001C4E;
  position: relative;
  z-index: 2;
}

@media (max-width: 50em){
  .microsite_card_contain {
    flex-direction: column;
  }

  .microsite_card {
    min-height: 220px;
  }

  .desc_microsite h3 {
    font-size: 1.1rem;
  }
  
  .desc_microsite p {
    font-size: 0.8rem;
  }
}
