.navbar_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  padding: 15px 60px;
  box-sizing: border-box;
  z-index: 11;
}

.stick_nav {
  position: fixed;
  top: 0px;
  z-index: 3;
  background-color: white;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
}

.navbar_logo_container {
  width: 145px;
}

.nav_logo {
  width: 85%;
}

.navigation_container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.navItem_container {
  display: flex;
  align-items: center;
}

.navigation_circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 20px;
  background: #707070;
}

.navItem_button {
  border: 2px solid #00196d;
  position: relative;
  padding: 8px 13px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 6%);
  border-radius: 3px;
  cursor: pointer;
}

.navItem_button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  background-color: #2484c6;
  transform: scaleX(0);
  z-index: -1;
  transform-origin: right;
  transition: transform 275ms ease-in-out;
}

.navItem_button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.navItem_button:active::after {
  background-color: #ffcb05;
}

.navItem_text {
  font-size: 14px;
  margin: 3px 0px;
  font-weight: 500;
  text-transform: uppercase;
}

.no_text_decoration {
  text-decoration: none;
  position: relative;
  color: #00196d;
  padding: 3px 7px;
  transition: transform 275ms ease-in-out, color 100ms ease-in-out;
}

.no_text_decoration:hover {
  color: white;
}

.no_text_decoration::after{
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: -1;
  background-color: #2484c6;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transform-origin: right;
  -webkit-transition: -webkit-transform 275ms ease-in-out;
  transition: -webkit-transform 275ms ease-in-out;
  -o-transition: transform 275ms ease-in-out;
  transition: transform 275ms ease-in-out;
  transition: transform 275ms ease-in-out, -webkit-transform 275ms ease-in-out;
}
.no_text_decoration:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  transform-origin: left;
}

.no_text_decoration:active {
  color: #00196d;
}

.no_text_decoration:active:after {
  background-color: #ffcb05;
}

.navItem_button > .navItem_text {
  color: #00196d;
  font-weight: 500;
  transition: color 0.2s;
}

.navItem_button:hover > .navItem_text {
  color: #fff;
}

.navItem_button:active > .navItem_text {
  color: #00196d;
}

.icons_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 145px;
}

.flexed_icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexed_icons:first-child {
  margin-bottom: 5px;
}

.search_icon {
  font-size: 20px;
  margin-right: 2px;
  cursor: pointer;
  transition: all 0.4s;
}

.menu_icon {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.4s;
}

.social_icon {
  font-size: 17px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.1s;
}

.social_icon_high {
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.1s;
}

.menu_icon:hover,
.search_icon:hover {
  color: #2484c6;
}

.social_icon_high:hover,
.social_icon:hover {
  transform: scale(1.2);
  color: #2484c6;
}

.mobile_menu {
  display: none;
}

.mobile_menu_icon {
  font-size: 32px;
  cursor: pointer;
}

.icon_close {
  background-color: #ffcb05;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 32px;
  cursor: pointer;
  border-bottom-left-radius: 5px;
}

.mobile_nav_container {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-image: url('../../resources/mobile_background.png');
  background-size: cover;
  background-position: center;
  top: 0px;
  left: 0px;
  z-index: 4;
  display: none;
}

.buttons_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.navigation_container_mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.social_flexed_icons_mob {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 30px;
}

.modal_container {
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.form_container {
  background-color: #f0f0f0;
  padding: 2em;
  position: relative;
  width: 30vw;
  border-radius: 10px;
}

.text_form {
  width: 100%;
}

.close_icon {
  font-size: 24px;
  color: white;
}

.status {
  font-size: 13px;
  margin: 0px;
  margin-top: 10px;
}

.button_submit {
  background-color: #2484c6;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.button_p {
  margin: 0px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.newsletter_sub_header {
  font-family: 'Playfair Display', serif;
  font-size: 1.2em;
  color: #00196d;
}

.modal_title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.weChatModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.95);
  cursor: pointer;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal_we_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .navbar_container {
    padding: 15px 40px;
  }

  .navbar_logo_container {
    width: 125px;
  }

  .navItem_text {
    font-size: 12px;
  }

  .social_icon {
    font-size: 15px;
  }

  .social_icon_high {
    font-size: 13px;
  }

  .search_icon {
    font-size: 17px;
  }

  .menu_icon {
    font-size: 25px;
  }
}

@media (max-width: 65em) {
  .navbar_container {
    height: 90px;
  }

  .navbar_logo_container {
    width: 125px;
  }

  .navigation_container {
    display: none;
  }

  .icons_container {
    display: none;
  }

  .mobile_menu {
    display: block;
  }

  .mobile_nav_container {
    display: flex;
    flex-direction: column;
  }

  .navItem_text {
    font-size: 14px;
  }

  .navItem_container {
    margin: 7px 0px;
  }

  .social_icon, .social_icon_high {
    margin-left: 11px;
  }
}

@media (max-width: 60em) {
  .navbar_container {
    padding: 1em 3em;
  }
  .form_container {
    width: 40vw;
}
}

@media (max-width: 40em) {
  .navbar_container {
    height: 70px;
    padding: 0.5em 1.5em;
  }

  .navbar_logo_container {
    width: 100px;
  }
}

@media (max-width: 550px) {
  .form_container {
      width: 90vw;
  }
  .qr_code {
    width: 85%;
  }
}


