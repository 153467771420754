.approach_container_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.approach_container_header h2{
  margin-top: 3em;
  z-index: 2;
  font-size: 2em;
  color: #FFCB02;
}

.approach_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4vw 8vw;
  position: relative;
}

.approach_left {
  padding-left: 4vw;
  width: 48%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}

.approach_right {
  padding-right: 4vw;
  width: 48%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}

.right_approach, .left_approach {
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.approach_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approach_para {
  font-size: 15px;
  width: calc(100% - 80px);
  color: white;
  background: rgba(23, 16, 33, 0.38);
  margin: 0px;
  padding: 0px 15px;

  border-radius: 6px;
}

.approach_para > p > b {
  color: #FFCB02;
}

.approach_icon {
  width: 70px;
  max-height: 80px;
  object-position: center;
  object-fit: contain;
}

.map_background {
  position: absolute;
  top: -200px;
  left: 5%;
  width: 90%;
  height: 100%;
}

.map_background_image {
  width: 100%;
  z-index: 1;
}

@media (max-width: 80em) {
  .approach_container {
    margin: 4vw 4vw;
  }

  .approach_left {
    padding-left: 3vw;
  }
  
  .approach_right {
    padding-right: 3vw;
  }

  .map_background {
    top: -100px;
  }

  .approach_container_header h2{
    font-size: 1.9em;
  }
}

@media (max-width: 60em) {
  .approach_left {
    padding-left: 2vw;
  }
  
  .approach_right {
    padding-right: 2vw;
  }

  .approach_icon {
    width: 60px;
  }

  .approach_para {
    font-size: 13px;
  }
  .map_background {
    top: 0px;
  }

  .approach_container_header h2{
    font-size: 1.8em;
  }
}

@media (max-width: 50em) {
  .approach_left {
    position: relative;
    width: 100%;
  }
  .approach_right {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
  }
  .approach_para {
    font-size: 12px;
  }
  .approach_icon {
    width: 55px;
  }
}

@media (max-width: 40em) {
  .approach_para {
    font-size: 10px;
  }
  .approach_icon {
    width: 50px;
  }
  .approach_container_header h2{
    font-size: 1.6em;
  }
}