.team_member_link {
  width: 18%;
  margin-right: 2%;
  cursor: pointer;
  text-decoration: none;
  color: unset;
}

.team_member {
  width: 100%;
}

.image_container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
}

.image_container::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  background-color: #42424233;
  border-radius: 5px;
  border: 2px solid #ffffffdd;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .275s ease-out;
}

.team_member:hover .image_container::before {
  transform: scaleX(1);
  transform-origin: left;
}

.team_member:active .image_container::before {
  background-color: #42424277;
}

/*.member_image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} */

.member_info_container {
  margin: 20px 0px;
}

.employee_name {
  font-size: 18px;
  margin: 0px;
  margin: 5px 0px;
}

.employee_desig {
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 700;
}

.noBoldDesig {
  font-weight: 400;
}

.employee_comp {
  font-size: 14px;
  margin: 5px 0px;
}

.employee_country {
  font-size: 12px;
  font-weight: 700;
  margin: 5px 0px;
}

@media (max-width: 60em){
  .team_member_link {
    width: 24%;
    margin-right: 1%;
  }
  .employee_name {
    font-size: 16px;
  }
  
  .employee_desig {
    font-size: 12px;
  }

  .employee_comp {
    font-size: 12px;
  }
  
  .employee_country {
    font-size: 10px;
  }
}

@media (max-width: 40em){
  .team_member_link {
    width: 31%;
    margin-right: 2%;
  }
  .employee_name {
    font-size: 14px;
  }
  
  .employee_desig {
    font-size: 10px;
  }

  .employee_comp {
    font-size: 10px;
  }
  
  .employee_country {
    font-size: 9px;
  }
}

@media (max-width: 380px){
  .team_member_link {
    width: 48%;
    margin-right: 2%;
  }
}
