.newsletter_issues_contain {
  margin: 10vw 15vw;
}

.yearly_issue_contain {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.monthly_issue_contain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 16.6%;
  padding: 8px 8px;
  border-radius: 2px;
}

.issue_text {
  margin: 2px 0px;
  cursor: pointer;
}

.month_header {
  margin-top: 5px; 
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-family: 'Playfair Display', serif;
  font-size: 1.1rem;
  color: #172F58;
  
}

.year_header {
  margin-top: 50px; 
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  color: #172F58;
}

.first_6:nth-child(even) {
  background-color: #f2f2f2;
  z-index: 1;
}

.last_6:nth-child(odd) {
  background-color: #f2f2f2;
  z-index: 1;
}

.issue_link {
  text-decoration: none;
}

.issue_text {
  position: relative;
  display: inline-block;
  transition: color 100ms ease-in-out;
  font-size: 1em;
}

.issue_text::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  background-color: #2484c6;
  transform: scaleX(0);
  z-index: -1;
  transform-origin: right;
  transition: transform 275ms ease-in-out;
}

.issue_text:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.issue_text:hover {
  color: white;
}

.issue_text:active {
  color: #172F58;
}

.issue_text:active::after {
  background-color: #ffcb05;
}

@media (max-width: 80em) {
  .issue_text {
    font-size: 0.95rem;
  }

  .year_header {
    font-size: 1.8rem;
  }
  
  .month_header {
    font-size: 1.1rem;
  }

  .monthly_issue_contain {
    background-color: white;
  }
}

@media (max-width: 60em) {
  .monthly_issue_contain {
    width: 25%;
  }

  .first_6:nth-child(even) {
    background-color: white;
  }

  .last_6:nth-child(odd) {
    background-color: white;
  }
  
}

@media (max-width: 40em) {
  .monthly_issue_contain {
    width: 33.3%;
  }

  .issue_text {
    font-size: 1rem;
  }

  .first_6:nth-child(even) {
    background-color: white;
  }

  .last_6:nth-child(odd) {
    background-color: white;
  }
}