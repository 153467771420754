.colored_background {
  background: #172F58;
  padding: 8vw 8vw;
  box-sizing: border-box;
}

.grey_colored_background {
  background: #F4F4F4;
  padding: 4.5vw 7vw;
  box-sizing: border-box;
}

.yt_centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.yt_contain {
  width: 65%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
}

.brochure_button {
  background-color: #FFCB02;
  margin-top: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
  cursor: pointer;
}

.broch_p {
  margin: 0px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.team_member_container {
  display: flex;
  flex-wrap: wrap;
}

.section_desc {
  margin-top: 0px;
  margin-bottom: 50px;
}

.para_zero_top_margin {
  margin-top: 0px;
}

.rolesList {
  font-weight: 500;
  font-size: 1.2rem;
}

.rolesList a{
  text-decoration: none;
  color: #172F58;
}

@media (max-width: 80em) {
  .yt_contain {
    width: 75%;
  } 
}

@media (max-width: 60em) {
  .yt_contain {
    width: 80%;
  } 
  .team_member_container {
    justify-content: flex-start;
  }

  .rolesList {
    font-size: 1.1rem;
  }
}

@media (max-width: 40em) {
  .yt_contain {
    width: 90%;
  }
}

@media (max-width: 450px) {
  .yt_contain {
    width: 100%;
  }
}