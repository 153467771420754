.social_icon {
  font-size: 17px;
  margin-left: 8px;
  cursor: pointer;
  transition: all .4s;
}

.social_icon_high {
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;
  transition: all .4s;
}

.contact_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1.75em;
}

.information_container {
  display: flex;
}

.ph_email_information_box {
  display: flex;
}

.information_box {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.info_icon {
  font-size: 16px;
  margin-right: 5px;
}

.info_icon_tall {
  font-size: 19px;
  margin-right: 5px;
}


@media (max-width: 80em) {
  .contact_details {
    flex-direction: column;
    justify-content: flex-start;
  }

  .information_box p{
    text-align: center;
    font-size: 0.8rem;
  }

  .social_icon, .social_icon_high {
    margin: 0px 4px;
  }

  .socials_container {
    display: flex;
    justify-content: center ;
  }
}

@media (max-width: 60em) {
  .information_container {
    flex-direction: column;
    align-items: center;
  }

  .ph_email_information_box p{
    display: flex;
    margin-bottom: 0;
  }

  .ph_email_information_box div{
    align-items: flex-end;
  }

  .information_box p{
    font-size: 0.7rem;
  }
}

@media (max-width: 40em) {
  .ph_email_information_box{
    flex-direction: column;
  }

  .information_container {
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .socials_container {
    margin-bottom: 10px;
  }

  .information_box p{
    font-size: 0.65rem;
  }
}