@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800&display=swap');

.resource_card_contain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
}

.resource_card{
  width: 33.3%;
  text-decoration: none;
}

.desc_resource_card {
  margin: 0px 5px;
}

.desc_resource_card h3{
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-size: 1rem;
  color: #172F58;
  margin: 10px 0px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator_line {
  height: 5px;
  width: 49%;
  background-color: #172F58;
}

.separator_skewed {
  height: 5px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}


.desc_resource_card p{
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  color: #434343;
  max-width: 25ch;
}

.img_resource_card {
  margin: 5px 5px;
  position: relative;
}

.img_resource_card::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 93%;
  background-color: #42424233;
  border-radius: 5px;
  border: 2px solid #ffffffcc;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .275s ease-out;
}

.image {
  max-width: 100%;
  border-radius: 5px;
}

.resource_card:hover .img_resource_card::before {
  transform: scaleX(1);
  transform-origin: left;
}

.resource_card:active .img_resource_card::before {
  background-color: #42424277;
}

.customize_head {
  margin-bottom: 20px;
}

@media (max-width: 794px){
  .project_head {
    margin-bottom: 20px;
  }
  .customize_head {
    margin-bottom: 40px;
  }
}
