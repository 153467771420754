.container {
    min-height: 300px;
    max-height: 340px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    margin: 75px 0px;
    text-decoration: none;
    color: black;
    position: relative;
    border-radius: 5px;
    transition: 0.5s ease;
}

.container:hover {
    background: #f5f5f5;
}


.photo_container {
    position: absolute;
    width: 18%;
    height: 100%;
    border-radius: 5px;
}

.photo_container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.post_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.types_container {
    display: flex;
    align-items: center;
}

.details_container {
    margin-left: 20%;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.details_container > h3 {
    font-size: 22px;
    margin: 0px;
}

.subtitle {
    font-size: 14px;
    margin: 5px 0px;
    margin-top: 15px;
}

.intro {
    font-size: 14px;
    margin: 5px 0px;
}

.topics_economy {
    font-size: 14px;
    margin: 5px 0px;
}

.topics_increased_margin {
    margin-top: 30px;
}

.type_text {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    background-color: #172F58;
    width: fit-content;
    padding: 7px 14px;
    border-radius: 50px;
    color: white;
}

.case_study {
    background-color: #2484c6;
    margin-left: 5px;
}

@media only screen and (max-width: 75em) {
    .photo_container {
        width: 22%;
    }
    .details_container {
        margin-left: 24%;
        width: 76%;
    }
}

@media only screen and (max-width: 68em) {
    .photo_container {
        width: 25%;
    }
    .details_container {
        margin-left: 28%;
        width: 72%;
    }
}

@media only screen and (max-width: 55em) {
    .photo_container {
        width: 30%;
    }
    .details_container {
        margin-left: 32%;
        width: 68%;
    }
    .details_container > h3 {
        font-size: 19px;
        margin: 0px;
    }
    
    .subtitle {
        font-size: 12px;
        margin: 5px 0px;
        margin-top: 15px;
    }
    
    .intro {
        font-size: 12px;
        margin: 5px 0px;
    }
    
    .topics_economy {
        font-size: 12px;
        margin: 5px 0px;
    }
    
    .topics_increased_margin {
        margin-top: 20px;
    }
    
    .type_text {
        font-size: 10px;
    }
}

@media only screen and (max-width: 48em) {
    .photo_container {
        width: 33%;
    }
    .details_container {
        margin-left: 35%;
        width: 65%;
    }
}

@media only screen and (max-width: 42em) {
    .photo_container {
        width: 36%;
    }
    .details_container {
        margin-left: 38%;
        width: 62%;
    }
    .type_text {
        font-size: 9px;
    }
}

@media only screen and (max-width: 38em) {
    .container {
        max-height: 400px;
        min-height: unset;
        align-items: flex-start;
    }
    .photo_container {
        width: 40%;
        height: 180px;
    }
    .details_container {
        margin-left: 44%;
        width: 54%;
    }
    .details_container > h3 {
        font-size: 17px;
        margin: 0px;
    }
    
    .subtitle {
        font-size: 10px;
        margin: 5px 0px;
        margin-top: 15px;
    }
    
    .intro {
        font-size: 10px;
        margin: 5px 0px;
    }
    
    .topics_economy {
        font-size: 10px;
        margin: 5px 0px;
    }
    
    .topics_increased_margin {
        margin-top: 18px;
    }
    
    .type_text {
        font-size: 7.5px;
    }
}