.container {
    background-color: rgba(255, 255, 255, 0.75);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner_container {
    position: relative;
    padding: 50px;
    width: 50vw;
    position: relative;
    background-color: #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
}

.newspaper {
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0px;
}

.paper_logo {
    width: 200px;
}

.flexed_container {
    display: flex;
    align-items: center;
}

.title {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    font-weight: 500;
    margin-left: 20px;
}

.close_icon_cont {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 8px 15px;
    border-bottom-left-radius: 10px;
    background-color: #222;
    cursor: pointer;
}

.close_icon {
    font-size: 24px;
    color: white;
}

.date {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 70em) {
    .inner_container {
        width: 70vw;
    }
}
  
@media only screen and (max-width: 60em) {
    .inner_container {
        width: 80vw;
        padding: 35px;
    }
    .newspaper {
        font-size: 14px;
    }
    
    .paper_logo {
        width: 150px;
    }
    .title {
        font-size: 24px;
    }
    .content {
        font-size: 14px;
    }
}

@media only screen and (max-width: 540px) {
    .flexed_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        margin-left: 0px;
    }
    .newspaper {
        font-size: 12px;
    }
    .paper_logo {
        width: 130px;
    }
    .title {
        font-size: 20px;
    }
    .content {
        font-size: 13px;
    }
    .date {
        font-size: 12px;
        margin-left: 0px;
    }
}