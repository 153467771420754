@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800&display=swap');

.news_card_contain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.news_card{
  width: 33.3%
}

.news_img_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 9px;
}

.news_header{
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1.35rem;
  color: #172F58;
  margin: 10px 0px;
  position: relative;
  display: inline-block;
  width: 60%;
}


.desc_news_card {
  margin: 0px 5px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator_line {
  height: 5px;
  width: 49%;
  background-color: #172F58;
}

.separator_skewed {
  height: 5px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}

.desc_news_card p{
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  color: #434343;
  max-width: 35ch;
}

.news_date {
  font-style: normal;
  font-weight: bold;
  color: #172F58;
  font-family: 'Playfair Display', serif;
}

.image_contain {
  width: 38%;
  padding-top: 38%;
  margin: 5px 5px;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid darkgrey;
  border-radius: 4px;
}

.image_contain::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  background-color: #43434322;
  border-radius: 2.5px;
  border: 2px solid #43434355;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.275s ease-out;
}

.news_card:hover .image_contain::before {
  transform: scaleX(1);
  transform-origin: left;
}

.news_card:active .image_contain::before {
  background-color: #43434355;
}

.image {
  width: 100%;
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.section_desc{
  font-size: 1.05rem;
  color: #172F58;
  margin: 20px 0px 30px 0px;
}

.section_desc a{
  text-decoration: none;
}

.media_button {
  color: #fff;
  background: #00196D;
  padding: 8px;
  text-decoration: none;
  margin-left: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.media_button:active {
  color: #ffcb05;
}

@media (max-width: 60em) {
  .section_desc {
    font-size: 0.95rem;
  }
 
  .news_header{
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 1.3rem;
  }

  .desc_news_card p {
    font-size: 0.8rem;
  }
}

@media (max-width: 40em) {
  .section_desc {
    font-size: 0.9rem;
  }

  .news_card{
    width: 50%
  }

  .news_header{
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 1rem;
  }

  .desc_news_card p {
    font-size: 0.7rem;
  }
}

@media (max-width: 675px) {
  .section_desc {
    line-height: 28px;
  }
}