.resource_tiles {
  background-color: #F2F2F2;
  padding: 10px 0px;
}

.card_description_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.card_description_contain p{
  width: 70%;
  font-size: 1rem;
  text-align: center;
}

.title {
  font-family: 'Playfair Display', serif;
  color: #172F58;
  font-size: 2rem;
  margin-bottom: 2px;
}

.resource_card_contain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card_contain_row {
  padding: 2.2vw 8.8vw 4.4vw 8.8vw;
}

.resource_card {
  cursor: pointer;
  text-decoration: none;
}

.card_contain_grid {
  padding: 2.2vw 19.8vw 4.4vw 19.8vw;
}

.resource_card_row {
  width: calc(25% - 16px);
  margin: 0px 0.5vw;
}

.resource_card_grid{
  width: calc(50% - 20px);
  margin: 0px 10px 30px 10px;
}

.desc_resource_card {
  margin: 0px 5px;
}

.desc_resource_card h3{
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #172F58;
  margin: 10px 0px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator_line {
  height: 5px;
  width: 49%;
  background-color: #172F58;
}

.separator_skewed {
  height: 5px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}


.desc_resource_card p{
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  color: #434343;
 /* max-width: 25ch; */
}

.img_resource_card {
  margin: 5px 5px;
  position: relative;
}

.img_resource_card::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 93%;
  background-color: #42424233;
  border-radius: 5px;
  border: 2px solid #ffffffdd;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .275s ease-out;
}

.image, .gridImage {
  width: 100%;
  border-radius: 5px;
  height: 220px;
  object-fit: cover;
  object-position: top;
}

.gridImage {
  height: 275px;
}

.resource_card:hover .img_resource_card::before {
  transform: scaleX(1);
  transform-origin: left;
}

.resource_card:active .img_resource_card::before {
  background-color: #42424277;
}

.convening_tool {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 12;
  display: flex;
}

.picture_container {
  background-color: #172F58;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tool_image {
  width: 80%;
  border-radius: 10px;
}

.info_container {
  width: 35%;
  box-sizing: border-box;
  padding: 20px 40px;
  padding-top: 80px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  overflow: scroll;
}

.info_container > h1 {
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 10px;
}

.tool_text {
  font-size: 16px;
}

.close_button_tool {
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 8px;
  background-color: #e0e0e0;
  font-size: 24px;
  padding: 5px 10px;
  cursor: pointer
}

@media (max-width: 60em) {
  .resource_card_row{
    width: calc(50% - 4vw);
    margin: 0px 2vw;
  }

  .title {
    font-size: 1.6rem;
  }

  .card_description_contain p{
    width: 80%;
    font-size: 0.95rem;
  }

  .desc_resource_card h3{
    font-size: 1.25rem;
  }

  .desc_resource_card p{
    font-size: 0.8rem;
  }

  .card_contain_grid {
    padding: 2.2vw 15vw 4.4vw 15vw;
  }
  .resource_card_grid{
    margin: 0px 10px 20px 10px;
  }
  .gridImage {
    height: 230px;
  }
}

@media (max-width: 50em) {
  .convening_tool {
    flex-direction: column;
  }
  .picture_container {
    width: 100%;
    padding: 60px 0px;
  }
  .info_container {
    width: 100%;
    padding-top: 0px;
    flex-grow: 1;
  }
}

@media (max-width: 40em) {
  .title {
    font-size: 1.4rem;
  }

  .card_description_contain p{
    width: 85%;
    font-size: 0.9rem;
  }

  .desc_resource_card h3{
    font-size: 1.1rem;
  }

  .desc_resource_card p{
    font-size: 0.75rem;
  }

  .card_contain_grid {
    padding: 2.2vw 8vw 4.4vw 8vw;
  }
  .gridImage {
    height: 180px;
  }
}

@media (max-width: 480px) {
  .resource_card_row {
    margin: 0px;
  }
  .gridImage {
    height: 175px;
  }
  .resource_card_grid {
    width: 100%;
  }
}
