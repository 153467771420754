.image_link {
  text-decoration: none;
  color: black;
}

.image_contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.image_parent {
  position: relative;
  padding-top: 255px;
  width: 92%;
  background-size: cover;
  background-position: center;
  border: 1px solid #e0e0e0;
}

.hover_foreground {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #42424200;
  transition: background-color 0.2s ease-out;
}

.image_parent::before {
  position: absolute;
  content: '';
  top: 5%;
  left: 2.5%;
  width: 95%;
  height: 90%;
  border-width: 2px 0px;
  border-color: rgb(255, 255, 255, 0.7);
  border-style: solid;
  transform: scaleX(0);
  z-index: 2;
  transition: transform 0.25s ease-out;
}

.image_parent::after {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 5%;
  width: 90%;
  height: 95%;
  border-width: 0px 2px;
  border-color: rgb(255, 255, 255, 0.7);
  border-style: solid;
  z-index: 3;
  transform: scaleY(0);
  transition: transform 0.25s ease-out;
}

.image_contain:hover .image_parent::before {
  transform: scaleX(1);
}

.image_contain:hover .image_parent::after {
  transform: scaleY(1);
}

.image_contain:hover .hover_foreground {
  background-color: #42424255;
}

.report_title {
  font-weight: 600;
  margin-bottom: 5px;
  padding-right: 8px;
}

.report_categ {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-right: 8px;
}

.report_year {
  margin-top: 5px;
  color: #383838;
  font-size: 14px;
  padding-right: 8px;
}

.slider_container {
  max-width: calc(75vw - 1.2em);
  padding-right: 35px;
  overflow: visible;
  padding-bottom: 30px;
}

.next_arrow, .prev_arrow {
  position: absolute;
  left: 0px;
  bottom: -30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #172F58;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  z-index: 2;
  transition: all 0.2s ease;
}

.next_arrow:hover , .prev_arrow:hover {
  transform: scale(1.075);
  color: #FFCB05;
}

.next_arrow {
  left: 40px;
}

.view_all_button {
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.view_all_button_child {
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  width: 92%;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  position: relative;
  color: #222;
  transition: transform 0.25s ease-out;
}

.view_all_button_child::before {
  position: absolute;
  content: '';
  top: 5%;
  left: 2.5%;
  width: 95%;
  height: 90%;
  border-width: 2px 0px;
  border-color: rgb(255, 255, 255, 0.7);
  border-style: solid;
  transform: scaleX(0);
  z-index: 2;
  transition: transform 0.25s ease-out;
}

.view_all_button_child::after {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 5%;
  width: 90%;
  height: 95%;
  border-width: 0px 2px;
  border-color: rgb(255, 255, 255, 0.7);
  border-style: solid;
  z-index: 2;
  transform: scaleY(0);
  transition: transform 0.25s ease-out;
}

.view_all_button:hover .view_all_button_child::before {
  transform: scaleX(1);
}

.view_all_button:hover .view_all_button_child::after {
  transform: scaleY(1);
}

.view_all_button:hover .view_all_button_child {
  color: #fff;
  background-color: #888;
}


@media (max-width: 80em){
  .slider_container {
    max-width: calc(100vw - 1.2em);
  }
  .image_parent {
    position: relative;
    padding-top: 300px;
  }
}

@media (max-width: 480px){
  .image_parent {
    position: relative;
    padding-top: 340px;
    width: 80%;
  }
}