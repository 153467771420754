.search_container_main {
    position: relative;
}


.doors_container {
    position: relative;
    height: calc(100vh - 110px);
}

.gradient_container {
    background-color: #00196d;
    opacity: 0.82;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
}

.images_container {
    display: flex;
    height: 100%;
}

.images_container > img {
    width: 25%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.absolute_content {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.increased_margin {
    margin: 0px 150px;
}

.search_bar_container {
    padding-top: 75px;
}

.search_bar_flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.searchBox {
    width: 85%;
}


.search_button {
    background-color: #FFCB05;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.search_button_text {
    margin: 0px;
}

.show_caps_only {
    display: flex;
    margin-top: 10px;
    color: white;
}

.checkbox_text {
    margin-bottom: 0px;
}

.quick_link_title {
    margin-top: 30px;
    font-weight: 600;
    color: #FFCB05;
}

.quick_search_containers {
    display: flex;
}
  
.search_text {
    font-size: 14px;
    color: #fff;
    margin: 0px;
}

.quick_search_item {
    border: 1px solid #fff;
    padding: 5px 12px;
    margin-right: 15px;
    border-radius: 50px;
    cursor: pointer;
}

@media only screen and (max-width: 1370px) {
    .searchBox {
        width: 78%;
    }
}

@media only screen and (max-width: 65em) {
    .doors_container {
      height: calc(100vh - 90px);
    }
    .increased_margin {
        margin: 0px 75px;
    }
  }

  @media only screen and (max-width: 55em) {
    .increased_margin {
        margin: 0px 25px;
    }
  }

  @media only screen and (max-width: 45em) {
    .increased_margin {
        margin: 0px 0px;
    }
  }
  
  @media only screen and (max-width: 40em) {
    .doors_container {
      height: calc(100vh - 70px);
    }
    .searchBox {
        width: 70%;
    }
  }

  @media only screen and (max-width: 480px) {
    .searchBox {
        width: 62%;
    }
}

@media only screen and (max-width: 380px) {
    .searchBox {
        width: 90%;
    }
    .search_button {
        margin-top: 20px;
    }
    .search_bar_flex {
        flex-direction: column;
        align-items: flex-start;
    }
}