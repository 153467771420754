.team_member_link {
  width: 21%;
  margin: 0px 2%;
  cursor: pointer;
  text-decoration: none;
  color: unset;
  margin-bottom: 60px;
}

.team_member {
  width: 100%;
}

.image_container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #e0e0e0;
}

.image_container::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 93%;
  background-color: #42424233;
  border-radius: 5px;
  border: 2px solid #ffffffdd;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .275s ease-out;
}

.team_member:hover .image_container::before {
  transform: scaleX(1);
  transform-origin: left;
}

.team_member:active .image_container::before {
  background-color: #42424277;
}

.member_info_container {
  margin: 20px 0px;
}

.employee_name {
  font-size: 18px;
  margin: 0px;
  margin: 5px 0px;
}

.types {
  font-size: 12px;
  margin: 4px 0px;
  font-weight: 500;
  color: #444;
}

@media (max-width: 50em) {
  .team_member_link {
    width: 31%;
    margin: 0px 1%;
  }
}

@media (max-width: 450px) {
  .team_member_link {
    width: 48%;
    margin: 0px 1%;
  }
}
