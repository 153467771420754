.workCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 10px 0px;
}

.description {
  text-align: center;
  color: #FFFFFF;
  font-size: 0.88rem;
}

.workTitle {
  text-align: center;
  color: #FFCB05;
  margin-top: 10px;
}

.figure {
  margin: 0.9vw 2.1vw;
}

.image {
  max-width: 100%;
  border-radius: 5px;
}

.descContain {
  background-color: #17102161;
  padding: 1.4vw 1.6vw;
  margin: 10px 10px;
  flex: 1 0 auto;
}

@media (max-width: 80em) {
  .workCard {
    width: 50%;
  }
  .description {
    font-size: 0.80rem;
  }
}

@media (max-width: 50em) {
  .workCard {
    width: 100%;
  }
  .description {
    font-size: 0.75rem;
  }
}
