.title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    padding: 50px 0px;
  }
  
  .title_text_container {
    width: 70%;
    box-sizing: border-box;
    padding: 0px 100px;    
  }
  
  .button_submit {
    background: #00196D;
    width: fit-content;
    padding: 12px 20px;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .button_p {
    margin: 0px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
  
  .status {
    font-size: 13px;
    margin: 0px;
    margin-top: 10px;
  }
  
  @keyframes title_image_anim {
    0% {border-width: 120px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
    30% {border-width: 120px; margin: 0px;box-shadow: 0px 0px 0px 0px #fff;}
    82% {border-width: 0px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
    100% {border-width: 0px; margin: 30px; box-shadow: 0px 0px 5px 5px #fff;}
  }
  
  .title_image {
    width: 105%;
    height: 105%;
    object-fit: cover;
    object-position: center;
  }
  
  .image_gradient_overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #444;
    opacity: 0.1;
    z-index: 2;
  }

  .gradient_container {
    background-color: #f5f5f5;
    opacity: 0.90;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
 }

 .title_text_container {
     position: relative;
     z-index: 2;
 }
  
  .title {
    font-weight: 400;
    font-size: 36px;
    margin: 0px;
    animation: header_anim 1s 1 ease-in-out;
  }

.doors_container {
    position: relative;
    height: calc(100vh - 110px);
    position: absolute; 
    top: 0px;
    left: 0px;
}

.images_container {
    display: flex;
    height: 100%;
}

.images_container > img {
    width: 25%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
  
  @keyframes header_anim {
    0% {letter-spacing: 0px;}
      40% {letter-spacing: 1.5px;}
      100% {letter-spacing: 0px; }
  }
  
  .title_separator {
    width: 175px;
    height: 7px;
    background: #2484c6;
    transform: skewX(25deg);
    margin-top: 18px;
    margin-bottom: 20px;
  }
  
  .desc {
    margin: 0px;
  }
  
  .form_container {
    display: flex;
    flex-direction: column;
  }
  
  .input_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
  }
  
  @media (max-width: 60em) {
    .title_image_container {
      display: none;
    }
  
    .title_text_container {
      width: 100%;
    }
  
  
    .title {
      font-size: 30px;
    }
  
    .form_container label{
      font-size: 0.8rem;
    }
  
    .title_text_container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  
  @media (max-width: 60em) {
  
    .title_text_container {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  