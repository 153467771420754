.research_section {
    background: #172F58;
    padding: 15px 25px;
    margin: 0px;
    border-radius: 5px;
    color: #fff;
    display: none;
}

.search_bar_container {
    background-color: #eee;
    padding: 15px 75px;
    border-radius: 10px;
}

.curr_search_term {
    font-weight: 700;
}

.search_select_container {
    display: flex;
    justify-content: space-between;
}

.searchBox {
    width: calc(100% - 200px);
}

.search_button {
    background-color: #00196D;
    color: #FFCB05;
    box-shadow: 0 0 12px 3px rgb(0 0 0 / 6%);
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 75px;
    text-transform: uppercase;
    font-size: 14px;
    transition: all .4s;
    cursor: pointer;
    font-weight: 600;
}

.search_button:hover {
    background: #ffcb05;
    color: #444;
  }

.search_check_box {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.next_button {
    background-color: #eee;
    padding: 10px;
    text-align: center;
    margin-bottom: 50px;
    cursor: pointer;
}

.fixed_filter_button {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 2;
}

.filter_icon {
    margin-right: 5px;
    font-size: 20px;
}

.fixed_filterButton {
    background-color: #2484c6;
    color: #fff;
    transition: 0.2s ease;
}

.fixed_filterButton:hover {
    background-color: #444;
}

.filters_container {
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
}

.filters_bg_container {
    background-color: #eee;
}

.no_posts {
    height: 75vh;
}

.no_post_heading {
    font-size: 24px;
    font-weight: 600;
}

.filter_menu_container {
    background-color: #F4F4F4;
    border: 1px solid #666;
    margin: 25px 0px;
}

.filter_dropdowns {
    display: flex;
}

.filter_dropdown {
    width: calc(25% - 1px);
    box-sizing: border-box;
    border-right: 1px solid #666;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
}

.filter_dropdown:last-child {
    border: 0px;
}

.filter_dropdown > p {
    margin-right: 0.4em;
}

.filters_bg_container {
    height: calc(100vh - 4em);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 50px 25px;
    position: relative;
}

.filter_heading {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.filters_container_inner {
    display: flex;
    justify-content: space-between;
}

.filter_section_long {
    width: 31%;
}

.filter_section {
    width: 17%;
}

.close_icon {
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50px
}

.filter_checkbox {
    display: flex;
    align-items: center;
    margin: -6px 0px;
    width: 50%;
}

.filter_label {
    margin: 10px 0px;
    font-size: 14px;
}

.filter_list_container {
    height: 58vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.filter_title {
    font-style: 16px;
    font-weight: 600;
    color: #444;
}

.buttons_container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.apply_button, .reset_button {
    padding: 10px 20px;
    cursor: pointer;
}

.apply_button {
    background-color: #2484c6;
}

.reset_button {
    background-color: #555;
}

.button_title {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    margin: 5px 5px;
}

.no_post_heading {
    text-align: center;
}

@media (max-width: 60em) {
    .filters_bg_container {
        height: calc(100vh - 2em);
    }
  }
  
@media (max-width: 40em) {
    .filters_bg_container {
        height: calc(100vh - 1em);
    }
  }

@media only screen and (max-width: 55em) {
    .filter_label {
        margin: 10px 0px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 50em) {
    .search_bar_container {
        padding: 15px 30px;
    }
    .filter_dropdown {
        font-size: 14px;
    }
}

@media only screen and (max-width: 45em) {
    .filter_dropdown {
        font-size: 12px;
    }
}

@media only screen and (max-width: 42em) {
    .filter_dropdowns {
        flex-wrap: wrap;
    }
    .filter_dropdown {
        width: 50%;
    }
    .filter_dropdown:nth-child(2) {
        border: 0px;
    }
}


@media only screen and (max-width: 800px) {
    .filters_bg_container {
        overflow: scroll;
    }
    .filters_container_inner {
        flex-wrap: wrap;
    }
    .filter_section, .filter_section_long {
        width: 50%;
    }
    .buttons_container {
        position: relative;
        border-radius: 10px;
        width: fit-content;
    }
    .filter_list_container {
        height: unset;
        margin: 20px 0px;
    }
}

@media only screen and (max-width: 530px) {
    .filter_section, .filter_section_long {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .search_bar_container {
        padding: 5px 15px;
    }
    .searchBox {
        width: calc(100% - 160px);
    }
    .search_button {
        width: 125px;
        font-size: 13px;
    }
    .search_check_box > p {
        font-size: 14px;
    }
}