.about_desc {
  display: flex;
  align-items: center;
  padding: 50px 0px ;
}

.info_title {
  font-size: 2.1rem;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  color: #172F58;
}

.conv_title {
  font-size: 1.8rem;
  margin-bottom: 0px
}

.image_container {
  margin-left: -6.5em;
  width: 40%;
  height: 325px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
}

.no_margin {
  margin-left: 0px;
}

.image, .image_a {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0px;
  top: 0px;
}

.image {
  animation-duration: 15s;
  animation-name: fade;
  animation-iteration-count: infinite;
}

.info_container {
  width: calc(60% + 6.5em);
  padding: 10px 40px;
  box-sizing: border-box;
}

.info_text {
  font-size: 1.1rem;
}

.conv_desc {
  font-size: 1.05rem;
}

.title_separator {
  width: 175px;
  height: 7px;
  background: #172F58;
  transform: skewX(25deg);
  margin-top: 18px;
  margin-bottom: 20px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 20px;
}

.separator_line {
  height: 6px;
  width: 175px;
  background-color: #172F58;
}

.separator_skewed {
  height: 6px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}

@keyframes fade {
  0%   { opacity: 1; }
  13.33%   { opacity: 1; }
  20%  { opacity: 0; }
  93.4%  { opacity: 0; }
  100% { opacity: 1; }
}

.image:nth-child(1) { animation-delay: 0s; }
.image:nth-child(2) { animation-delay: 3s; }
.image:nth-child(3) { animation-delay: 6s; }
.image:nth-child(4) { animation-delay: 9s; }
.image:nth-child(5) { animation-delay: 12s; }


@media only screen and (max-width: 1075px) {
  .title_separator {
    height: 5px;
  }
}

@media (max-width: 80em) {
  .info_title {
    font-size: 1.6rem;
  }

  .info_text {
    font-size: 0.95rem;
  }
}

@media (max-width: 60em) {
  .info_title {
    font-size: 1.4rem;
  }

  .info_text {
    font-size: 0.9rem;
  }

  .conv_title {
    font-size: 1.6rem;
  }

  .conv_desc {
    font-size: 0.95rem;
  }
}

@media (max-width: 50em) {
  .about_desc {
    flex-direction: column;
  }

  .image_container {
    width: 100%;
    margin-left: 0em;
    border-radius: 0px;
  }

  .info_container {
    width: 100%;
    padding: 0px;
  }
}

@media (max-width: 40em) {
  .conv_title {
    font-size: 1.5rem;
  }

  .conv_desc {
    font-size: 0.90rem;
  }
  .about_desc {
    padding-bottom: 0px;
  }

}
