.flexed_content_container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.image_container {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title_image {
  width: 90%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  background-position: top left !important;
}

.title_image_img {
  width: 100%;
  height: 100%;
}

.button,
.microsite_button {
  background-color: #2484c6;
  border-radius: 5px;
  padding: 15px 15px;
  box-sizing: border-box;
  width: 90%;
  margin: 15px 0px;
  cursor: pointer;
  font-size: 14px;
}

.microsite_button {
  background-color: #546e7a;
  color: white;
  width: fit-content;
  padding: 10px 40px;
  margin: 20px 0px;
  border-radius: 50px;
  font-size: 14px;
}

.button_text {
  margin: 0px;
  font-style: 14px;
  color: white;
  text-align: center;
  text-transform: capitalize;
}

.post_content_container {
  width: 75%;
}

.title {
  font-family: "Playfair Display", serif;
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 500;
}

.subtitle {
  font-size: 16px;
  margin: 10px 0px;
}

.topics_economy {
  font-size: 14px;
  margin: 5px 0px;
}

.separator {
  width: 250px;
  height: 9px;
  background-color: #2484c6;
  margin: 20px 0px;
  transform: skewX(30deg);
}

.wp_content_container {
  position: relative;
}

.wp_content_container > div > h1 {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 26px;
}

.wp_content_container > div > p > a,
.wp_content_container > div > p > strong > a,
.wp_content_container > div > p > b > a {
  color: black;
}

.wp_content_container > div > p > iframe {
  width: 70%;
  margin-top: 30px;
  margin-left: 15%;
  height: 400px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
}

@media (max-width: 80em) {
  .image_container {
    width: 28%;
  }
  .post_content_container {
    width: 70%;
  }
  .wp_content_container > div > p > iframe {
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 60em) {
  .flexed_content_container {
    flex-direction: column;
  }

  .image_container {
    flex-direction: row;
    width: 80%;
  }

  .title_image {
    width: 60%;
  }

  .buttons_container {
    margin-left: 20px;
    width: 40%;
  }
  .post_content_container {
    width: 100%;
  }

  .wp_content_container > div > p > iframe {
    width: 100%;
    margin-left: 0%;
  }
}

@media (max-width: 50em) {
  .title {
    font-size: 26px;
  }

  .image_container {
    width: 100%;
  }

  .title_image {
  }

  .subtitle {
    font-size: 14px;
    margin: 10px 0px;
  }

  .topics_economy {
    font-size: 12px;
    margin: 5px 0px;
  }

  .separator {
    width: 220px;
    height: 7px;
  }

  .wp_content_container {
    position: relative;
    font-size: 14px;
  }

  .wp_content_container > div > h1 {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 22px;
  }

  .button,
  .microsite_button {
      padding: 10px 10px;
  }
}

@media (max-width: 45em) {
  .flexed_content_container {
    flex-direction: column;
  }

  .post_content_container {
    width: 100%;
  }

  .title_image {
  }

  .button,
  .microsite_button {
    font-size: 12px;
  }
}

@media (max-width: 40em) {
  .content_container {
    padding: 0.5em 1.5em;
  }

  .title_image {
    height: 400px;
  }

  .button,
  .microsite_button {
    font-size: 10px;
  }
}

@media (max-width: 35em) {
  .title_image {
  }
  .microsite_button {
    font-size: 15px;
  }
}

@media (max-width: 30em) {
  .title_image {
  }
  .microsite_button {
    font-size: 14px;
  }
}


@media (max-width: 25em) {
    .title_image {
      height: 270px;
    }
  }

@media (max-width: 25em) {
.title_image {
}
}
