.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title_text_container {
  width: 50%;
  box-sizing: border-box;
  padding: 0px 100px;
}

.title_image_container {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 110px);
  border-radius: 12px 0px 0px 0px;
}

.title_image_container::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-color: #ffffffE6;
  border-style: solid;
  border-width: 0px;
  z-index: 3;
  box-shadow: 0px 0px 5px 5px #fff; 
  margin: 30px;
  animation: title_image_anim 0.75s ease-in;
}

.button_submit {
  background-color: #2484c6;
  width: fit-content;
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.button_p {
  margin: 0px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.status {
  font-size: 13px;
  margin: 0px;
  margin-top: 10px;
}

@keyframes title_image_anim {
  0% {border-width: 120px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
  30% {border-width: 120px; margin: 0px;box-shadow: 0px 0px 0px 0px #fff;}
  82% {border-width: 0px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
  100% {border-width: 0px; margin: 30px; box-shadow: 0px 0px 5px 5px #fff;}
}

.title_image {
  width: 105%;
  height: 105%;
  object-fit: cover;
  object-position: center;
}

.image_gradient_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #444;
  opacity: 0.1;
  z-index: 2;
}

.title {
  font-weight: 400;
  font-size: 36px;
  margin: 0px;
  animation: header_anim 1s 1 ease-in-out;
}

@keyframes header_anim {
  0% {letter-spacing: 0px;}
    40% {letter-spacing: 1.5px;}
    100% {letter-spacing: 0px; }
}

.title_separator {
  width: 175px;
  height: 7px;
  background: #2484c6;
  transform: skewX(25deg);
  margin-top: 18px;
  margin-bottom: 20px;
}

.desc {
  margin: 0px;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
}

.information_container p{
  margin-top: 2px;
  font-size: 1;
}

.ph_email_information_box {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.information_box_email {
  margin-left: 10px;
}

.information_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.information_box > p {
  font-size: 15px;
  margin: 0px
}

.info_icon_tall {
  font-size: 20px;
  margin-left: -4px;
}

@media (max-width: 60em) {
  .title_image_container {
    display: none;
  }

  .title_text_container {
    width: 100%;
  }


  .title {
    font-size: 30px;
  }

  .form_container label{
    font-size: 0.8rem;
  }

  .title_text_container {
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url(../../../resources/doors/ContactMobBG.jpg);
    background-size: cover;
  }
}

@media (max-width: 60em) {

  .title_text_container {
    padding: 30px 50px;
  }

  .information_container p{
    font-size: 0.9rem;
  }
}
