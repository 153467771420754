.work_type_section {
  background-color: #172F58;
  min-height: 100vh;
  background-position: calc(100%/2 + 0.6vw) center;
  background-size: 60%;
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-bottom: 50px;
}

.work_card_contain {
  padding: 1.2vw 6vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 80em) {
  .work_card_contain {
    padding: 1.2vw 15vw;
  }
}

@media (max-width: 50em) {
  .work_card_contain {
    padding: 1.2vw 20vw;
  }
}