.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.title_text_container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.title_image_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 275px;
}

.title_image {
  width: calc(100vw/3);
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title_white_bg {
  background: white;
  padding: 2vw 1.5vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
}

.title {
  margin: 0px;
  text-align: center;
  color: #172F58;
  font-size: 2rem;
}

.subtitle {
  margin: 0px;
  text-align: center;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
}

.title_separator {
  width: 175px;
  height: 7px;
  background: #2484c6;
  transform: skewX(25deg);
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Asian Voice title styling below */

.image_gradient_overlay {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(23, 47, 88, 1), rgba(23, 47, 88, 1), rgba(23, 47, 88, 1), rgba(23, 47, 88, 0.8), rgba(23, 47, 88, 0.5));
}

.asian_voices_flexed_content {
  display: flex;
  align-items: center;
}

.asian_voices_image {
  width: 240px;
  height: 240px;
  margin-right: 40px;
  border-radius: 8px;
}

.asian_voices_content {
  width: calc(100% - 240px);
}

.voice_title {
  color: #FFCB05;
  font-size: 30px;
  margin: 5px 0px;
}

.flexed_role {
  display: flex;
  margin-bottom: 15px;
}

.types {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px;
  margin-right: 15px;
}

.voices_info, .country {
  font-size: 12px;
  margin: 5px 0px;
  color: #fff;
}

.country {
  color: #FFCB05;
  margin-top: 10px;
  font-weight: 500;
}

.left_align {
  justify-content: flex-start;
  padding: 0px calc(6em + 100px);
}

.asian_voice_images {
  height: 325px;
}

/*
@media (max-width: 80rem) {
  .title_image_container {
    height: 250px;
  }
}

@media (max-width: 60rem) {
  .title_white_bg {
    min-width: 300px;
  }
  
  .title {
    font-size: 1.6rem;
  }
  
  .subtitle {
    font-size: 0.9rem;
  }

  .title_image_container {
    height: 220px;
  }
}

@media (max-width: 40rem) {
  .title_white_bg {
    min-width: 250px;
  }
  
  .title {
    font-size: 1.5rem;
  }
  
  .subtitle {
    font-size: 0.85rem;
  }

  .title_image_container {
    height: 175px;
  }
}
*/


@media only screen and (max-width: 50em) {
  .title {
    font-size: 28px;
  }
  .title_separator {
    width: 140px;
    height: 6px;
  }
  .subtitle {
    font-size: 14px;
  }
  .title_white_bg {
    min-width: 58%;
  }

}

@media only screen and (max-width: 50em) {
  .title_white_bg {
    min-width: 75%;
  }
}


@media only screen and (max-width: 70em) {
  .left_align {
    justify-content: flex-start;
    padding: 0px calc(6em + 70px);
  }
}

@media only screen and (max-width: 60em) {
  .left_align {
    padding: 0px calc(3em + 60px);
  }
}

@media only screen and (max-width: 45em) {
  .left_align {
    padding: 0px calc(3em + 20px);
  }
  .asian_voices_image {
    width: 200px;
    height: 200px;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .asian_voices_content {
    width: calc(100% - 200px);
  }
  .voice_title {
    color: #FFCB05;
    font-size: 26px;
  }
}

@media only screen and (max-width: 40em) {
  .left_align {
    padding: 0px calc(1em + 20px);
  }

  .flexed_role {
    flex-direction: column;
  }
}

@media only screen and (max-width: 501px) {
  .asian_voices_image {
    width: 100px;
    height: 140px;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .asian_voices_content {
    width: calc(100% - 100px);
  }
  .voice_title {
    font-size: 16px;
  }
  .types {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin: 3px 0px;
    margin-right: 15px;
  }
  
  .voices_info, .country {
    font-size: 10px;
    margin: 5px 0px;
  }
  .title_image_container {
    height: 190px;
  }
}
