.padding_work_p {
    padding-right: 6em;
}

.comm_research_butt {
    color: #00196d;
    text-decoration: underline;
}

@media (max-width: 60em) {
    .padding_work_p {
        padding-right: 3em;
    }
  }
  
  @media (max-width: 40em) {
    .padding_work_p {
        padding-right: 1.5em;
    }
  }