.vizContain {
  margin: 20px 0px;
}

@media (max-width: 60em) {
  .vizContain {
    transform: translateX(3.5vw) scale(1.05);
  }
}

@media (max-width: 40em) {
  .vizContain {
    transform: translateX(4vw) scale(1.1);
  }
}