.modalData {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goBackWhite {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.80);
  cursor: pointer;
}

.data_container {
  width: 70vw;
  display: flex;
  position: relative;
  z-index: 4;
  border-radius: 10px;
  overflow: hidden;
}

.photo_container {
  background: #2484C6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 15px;
  box-sizing: border-box;
  width: 30%;
  position: relative;
}

.paragraph_container {
  background: #172F58;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  box-sizing: border-box;
  width: 70%;
}

.paragraph_container > div > p {
  color: white;
  font-size: 14px;
  margin: 10px 0px;
}

.image_container {
  width: 100%;
  padding-top: 100%;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 10px;
  margin-bottom: 10px;
}

.separator {
  width: 70%;
  height: 6px;
  background: white;
  transform: skewX(35deg);
  margin: 15px 0px;
}

.info_container {
  color: white;
}

.employee_name {
  font-size: 30px;
  margin: 15px 0px;
}

.employee_desig, .employee_comp, .employee_country {
  font-size: 14px;
  margin: 5px 0px;
}

.employee_desig, .employee_country {
  font-weight: 600;
}

.noBoldDesig {
  font-weight: 400;
}

.close_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  font-size: 21px;
  border-top-left-radius: 5px;
  background: #FFCB05;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 80em){
  .data_container {
    width: 85vw;
  }
}

@media (max-width: 68em){
  .data_container {
    width: 94vw;
  }
}

@media (max-width: 60em){
  .data_container {
    width: 96vw;
  }
}

@media (max-width: 57em){
  .data_container {
    width: 100vw;
    height: 100%;
  }
}

@media (max-width: 780px){
  .data_container {
    flex-direction: column;
  }

  .photo_container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .image_container {
    width: 30%;
    padding-top: 30%;
  }

  .info_container {
    width: 55%;
  }

  .paragraph_container {
    width: 100%;
    overflow: scroll;
    flex-grow: 1;
    justify-content: flex-start;
  }
}

@media (max-width: 500px){
  .image_container {
    width: 35%;
    padding-top: 35%;
  }
}