.media_tiles_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 5vw 13vw;
}

.news_card{
  width: 32.3%;
  margin-left: 1%;
  margin-bottom: 3%;
  text-decoration: none;
}


.news_img_header {
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
}

.news_header{
  width: 60%;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1rem;
  color: #172F58;
  margin: 10px 0px;
  position: relative;
  display: inline-block;
}


.desc_news_card {
  margin: 0px 5px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator_line {
  height: 5px;
  width: 49%;
  background-color: #172F58;
}

.separator_skewed {
  height: 5px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}

.desc_news_card p{
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  color: #434343;
  max-width: 35ch;
}

.news_date {
  font-style: normal;
  font-weight: bold;
  color: #172F58;
  font-family: 'Playfair Display', serif;
}

.image_contain {
  width: 40%;
  padding-top: 40%;
  margin: 5px 5px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border: 2px solid grey;
}

.image_contain::before {
  position: absolute;
  content: '';
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  background-color: #43434322;
  border-radius: 2.5px;
  border: 2px solid #43434355;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.275s ease-out;
}

.news_card:hover .image_contain::before {
  transform: scaleX(1);
  transform-origin: left;
}

.news_card:active .image_contain::before {
  background-color: #43434355;
}

.image {
  width: 100%;
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.section_desc{
  font-size: 1.05rem;
  color: #172F58;
  margin-top: 5px;
}

.section_desc a{
  text-decoration: none;
}

.media_button {
  background-color: #2484C6;
  color: white;
  padding: 0px 5px;
  margin-left: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.media_button:active {
  background-color: #172F58;
}

.next_button {
  background-color: #eee;
  padding: 10px;
  text-align: center;
  margin-bottom: 50px;
  cursor: pointer;
}

@media (max-width: 80em) {
  .media_tiles_container {
    margin: 4vw 10vw;
  }
}

@media (max-width: 60em) {
  .section_desc {
    font-size: 0.95rem;
  }
 
  .news_header{
    font-size: 1.1rem;
  }

  .desc_news_card p {
    font-size: 0.8rem;
  }
  
  .news_card{
    width: 48%;
    margin-left: 2%;
    margin-bottom: 3%;
  }
}

@media (max-width: 40em) {
  .section_desc {
    font-size: 0.9rem;
  }

  .news_card{
    width: 50%
  }

  .news_header{
    font-size: 1.2rem;
  }

  .desc_news_card p {
    font-size: 0.7rem;
  }

  .news_card{
    width: 100%;
    margin-bottom: 3%;
  }
}