.modal_container {
    background-color: rgba(255, 255, 255, 0.75);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  
  .form_container {
    background-color: #f0f0f0;
    padding: 2em;
    position: relative;
    width: 30vw;
    border-radius: 10px;
  }
  
  .text_form {
    width: 100%;
  }
  
  .close_icon {
    font-size: 24px;
    color: white;
  }
  
  .status {
    font-size: 13px;
    margin: 0px;
    margin-top: 10px;
  }
  
  .button_submit {
    background-color: #2484c6;
    width: fit-content;
    padding: 12px 20px;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .button_p {
    margin: 0px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
  
  .newsletter_sub_header {
    font-family: 'Playfair Display', serif;
    font-size: 1.2em;
    color: #00196d;
  }

  .icon_close {
    background-color: #ffcb05;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 32px;
    cursor: pointer;
    border-bottom-left-radius: 5px;
  }

  .sub {
      font-size: 14px;
      margin: 0px 0px;
  }

  .sub > i {
      margin: 5px 0px;
  }

@media (max-width: 60em) {
    .form_container {
        width: 40vw;
    }
}

@media (max-width: 550px) {
    .form_container {
        width: 90vw;
    }
}