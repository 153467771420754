.loader_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content_container {
  padding: 2em 6em;
  box-sizing: border-box;
}

.content_no_top_margin {
  padding: 0em 6em;
  box-sizing: border-box;
}

.flexed_container {
  display: flex;
  margin-top: 70px;
}

.side_content_section {
  flex: 0 0 75%;
}

.background_grey {
  background-color: #F4F4F4;
}

.colored_span {
  color: #254D91;
  font-weight: 500;
}

@media (max-width: 60em) {
  .content_container {
    padding: 1em 3em;
  }
}

@media (max-width: 40em) {
  .content_container {
    padding: 0.5em 1.5em;
  }
}


@media (max-width: 80em) {
  .flexed_container {
    flex-direction: column;
  }
}
