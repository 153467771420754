.content_container {
  margin: 5em 15em;
}

.image_and_authorinfo {
  display: flex;
  justify-content: flex-start;
}

.author_and_title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1rem;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 1.3rem;
}

.image_author {
  width: 200px;
  height: 250px;
  flex-grow: 0;
  flex-shrink: 0;
}

.image_insights {
  width: 300px;
  height: 130px;
}

.blog_content_contain {
  margin: 3rem 0rem;
}

@media (max-width: 80em){
  .content_container {
    margin: 4em 12em;
  }
}

@media (max-width: 60em){
  .content_container {
    margin: 2em 6em;
  }
}

@media (max-width: 40em){
  .content_container {
    margin: 1em 3em;
  }
}
