html, body {
  margin: 0px;
  width: 100%;
}


.audio_control_container {
  display: flex;
  justify-content: center;
}

.audio_control_container > a {
  margin: 0px 10px;
}

.audio_control_container > a > img {
  width: 200px;
  height: auto;
  margin: 0px;
}

.slick-track {
  margin-left: 0px !important;
}

.searchBox > .MuiInputLabel-root {
  color: white !important;
}

.searchBox > .MuiInput-root {
  color: white !important;
}

.searchBox > .MuiInput-root:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
}

.searchBox > .MuiInput-root:after {
  border-bottom: 2px solid #2484c6 !important;
}

.invert_check {
  color: rgba(255, 255, 255, 0.7) !important;
}

