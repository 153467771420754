@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800&display=swap');

.sidehead_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 3em 6vw;

}

.side_section_header {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.side_section_header h2{
  display: inline-block;
  font-family: 'Playfair Display', serif;
  font-weight: 500;
  font-size: 1.8rem;
  color: #172F58;
  margin-top: 16px;
  padding-top: 10px;
  margin-bottom: 0px;
}

.separator_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator_line {
  height: 6px;
  width: 70%;
  background-color: #172F58;
}

.separator_skewed {
  height: 6px;
  width: 15px;
  transform: skewX(-25deg);
  background-color: #172F58;
  margin-left: -5px;
}

.impactData {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 60%;
  margin: 10px 0px;
}

.impactImgNumber {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.impactDatum {
  margin: 10px 0px;
  background-color: #F2F2F2;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
}

.impactDatum img {
  width: 30px;
} 

.impactDatum::after {
  position: relative;
  content: attr(datum_desc);
  max-width: 50%;
  font-size: 0.85rem;
}

.impactDatumNumber {
  color: #172F58;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 15px 6px;
}

.section_pres {
  flex: 1 1 75%;
}

.section_desc{
  font-size: 1.05rem;
  color: #172F58;
  margin-top: 0px;
}

.button {
  background-color: #2484c6;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
  cursor: pointer;
  width: fit-content;
}

.button > p {
  font-size: 14px;
  color: white;
  margin: 0px
}

@media (max-width: 80em){
  .sidehead_section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .side_section_header {
    padding-right: 0px;
    width: fit-content;
  }

  .impactData {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
}

@media (max-width: 60em){
  .side_section_header h2 {
    font-size: 1.6rem;
  }

  .section_desc{
    font-size: 0.95rem;
  }

  .impactDatumNumber {
    font-size: 1.2rem;
  }

  .impactDatum::after {
    position: relative;
    content: attr(datum_desc);
    max-width: 50%;
    font-size: 0.80rem;
  }
}

@media (max-width: 40em){
  .side_section_header h2 {
    font-size: 1.5rem;
  }

  .section_desc{
    font-size: 0.9rem;
    padding-right: 6vw;
  }

  .impactData {
    flex-wrap: wrap;
    width: 60%;
  }
}

