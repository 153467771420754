.footer_container {
  padding: 40px 60px;
  box-sizing: border-box;
  margin-top: 0px;
  background: #F2F2F2;
  z-index: 3;
  position: relative;
}

.form_logo_flexed {
  display: flex;
}

.footer_heading {
  font-weight: 600;
  margin: 0px;
  font-size: 30px;
  margin-bottom: 5px;
}

.form_parent {
  width: 60%;
}

.form_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form_container label{
  font-size: 16px;
}
 

.textfield_container {
  width: 47%;
  margin: 5px 0px;
}

.textfield {
  width: 100%;
}

.logo_container {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.footer_logo {
  width: 150px;
}

.subscribe_button {
  background: #00196D;
  color: #FFCB05;
  padding: 11px 20px;
  width: 150px;
  border-radius: 50px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 6%);
  cursor: pointer;
  transition: all .4s;
}

.subscribe_button:hover {
  background: #ffcb05;
}

.button_text {
  margin: 0px;
  transition: all .4s;
  font-weight: 500;
}

.subscribe_button:hover > .button_text {
  color: #444;
}

.copyright_social_container {
  margin-top: 50px;
}

.social_icon {
  font-size: 18px;
  margin-left: 8px;
  cursor: pointer;
  transition: all .4s;
}

.social_icon_longer {
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: all .4s;
}

.copyright_social_container {
  display: flex;
}

.copyright_container {
  display: flex;
  flex-grow: 1;
}

.social_navigation_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navigation_container {
  display: flex;
  align-items: center;
}

.social_icons_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.footer_nav_separator {
  width: 1px;
  height: 75%;
  margin: 0px 10px;
  background: black;
}

.footer_navItem_container {
  cursor: pointer;
}

.footer_navItem {
  font-size: 14px;
  margin: 4px 0px;
  color: black;
}

.footer_navItem:hover {
  color: #00196D;
}

.footer_navItem_container:after {
  display:block;
  content: '';
  border-bottom: solid 2px #019fb6;
  -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: -webkit-transform 275ms ease-in-out;
  transition: -webkit-transform 275ms ease-in-out;
  -o-transition: transform 275ms ease-in-out;
  transition: transform 275ms ease-in-out;
  transition: transform 275ms ease-in-out, -webkit-transform 275ms ease-in-out;
}
.footer_navItem_container:hover:after { -webkit-transform: scaleX(1); -ms-transform: scaleX(1); transform: scaleX(1); }

.copyright_message {
  font-size: 14px;
  font-weight: 500;
}

.social_icon:hover, .social_icon_longer:hover {
  transform: scale(1.2);
  color: #2484C6;
}

.modal_title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.weChatModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 30;
  background-color: rgba(255,255,255,0.95);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1075px) {
  .footer_container {
    padding: 30px 40px;
  }

  .footer_heading {
    font-size: 27px;
  }

  .footer_logo {
    width: 130px;
  }

  .subscribe_button {
    padding: 11px 20px;
    width: 130px;
  }

  .button_text {
    font-size: 14px;
  }

  .social_icon {
    font-size: 16px;
  }

  .social_icon_longer {
    font-size: 14px;
  }

  .copyright_message {
    font-size: 13px;
  }

  .footer_navItem {
    font-size: 13px;
  }
}


@media (max-width: 60em) {
  .footer_container {
    padding: 1em 3em;
  }

  .copyright_social_container {
    flex-direction: column;
    margin-top: 25px;
  }

  .social_icons_container {
    justify-content: flex-start;
  }

  .form_logo_flexed {
    flex-direction: column-reverse;
  }

  .logo_container {
    justify-content: flex-start;
    margin: 25px 0px;
  }

  .form_parent {
    width: 75%;
  }

  .footer_logo {
    width: 120px;
  }

  .footer_heading {
    font-size: 24px;
  }

  .form_container label{
    font-size: 14px;
  }

  .copyright_message {
    font-size: 12px;
    font-weight: 400;
  }

  .footer_navItem {
    font-size: 12px;
  }
}

@media (max-width: 40em) {
  .footer_container {
    padding: 0.5em 1.5em;
  }

  .copyright_social_container {
    flex-direction: column;
    margin-top: 15px;
  }

  .form_parent {
    width: 100%;
  }
  .footer_heading {
    font-size: 20px;
  }

  .form_container label{
    font-size: 12px;
  }

  .copyright_message {
    font-size: 10px;
    font-weight: 400;
  }

  .footer_navItem {
    font-size: 10px;
  }
}

@media (max-width: 550px) {
  .qr_code {
    width: 85%;
  }
}


