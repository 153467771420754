.quote_section {
  background-image: linear-gradient(to right, #2484C6 0%, #2484C6 50%, #2E5EB1 100%);
  padding: 50px 18%;
}

.quote_container {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.quoter_image{
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.quoter_image img{
  width: 100%;
  border: 2px solid white;
}

.quoter {
  font-size: 1rem;
}

.quote_contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  width: 75%;
  padding: 1em 0em;
}

.quote_image {
  width: 8%;
}

.quote {
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: uppercase;
  line-height: 1.4;
  position: relative;
}

.quote::before {
  position: absolute;
  content: '"';
  top: -50px;
  left: 0px;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  color: #E0E0E0;
}

.quote::after {
  position: absolute;
  content: '"';
  bottom: -75px;
  right: 0px;
  font-size: 4rem;
  font-family: 'Playfair Display', serif;
  color: #E0E0E0;
}

.flexed_video_container {
  display: flex;
  height: 300px;
  justify-content: space-between;
  margin: 0px -10%;
}

.yt_centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 47%;
}

.yt_contain {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 12px 3px rgb(0 0 0 / 16%);
}

@media (max-width: 70em){
  .quoter_image {
    display: none;
  }
}

@media (max-width: 60em){
  .quote {
    font-weight: 500;
    font-size: 1rem;
  }

  .quoter {
    font-size: 0.95rem;
  }
}

@media (max-width: 50em){
  .flexed_video_container {
    flex-direction: column;
    margin: 0px;
    height: auto;
  }
  .yt_centered {
    width: 100%;
    margin-bottom: 40px;
  }

}

@media (max-width: 40em){
  .quote {
    font-weight: 500;
    font-size: 0.95rem;
  }

  .quoter {
    font-size: 0.85rem;
  }
}