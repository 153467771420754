.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.title_text_container {
  width: 50%;
  box-sizing: border-box;
  padding: 0px 100px;
}

.title_image_container {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 110px);
  border-radius: 12px 0px 0px 0px;
  z-index: 1;
}

.title_image_container::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-color: #ffffffE6;
  border-style: solid;
  border-width: 0px;
  border-radius: 0px;
  z-index: 5; 
  box-shadow: 0px 0px 5px 5px #fff; 
  margin: 2.2vw;
  animation: title_image_anim 1s ease-in;
}


@keyframes title_image_anim {
  0% {border-width: 120px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
  30% {border-width: 120px; margin: 0px;box-shadow: 0px 0px 0px 0px #fff;}
  82% {border-width: 0px; margin: 0px; box-shadow: 0px 0px 0px 0px #fff;}
  100% {border-width: 0px; margin: 2.2vw; box-shadow: 0px 0px 5px 5px #fff;}
}


.title_image {
  width: 105%;
  height: 105%;
  object-fit: cover;
  object-position: center;
}

.image_gradient_overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #172F5800, #172F58FF 80%, #172F58FF 100%);;
  opacity: 0.1;
  z-index: 2;
}

.title {
  font-weight: 400;
  font-size: 36px;
  margin: 0px;
  animation: header_anim 1s 1 ease-in-out;
}

@keyframes header_anim {
  0% {letter-spacing: 0px;}
    40% {letter-spacing: 1.5px;}
    100% {letter-spacing: 0px; }
}
    


.title_separator {
  width: 175px;
  height: 7px;
  background: #2484c6;
  transform: skewX(25deg);
  margin-top: 18px;
  margin-bottom: 20px;
}

.desc {
  margin: 0px;
}

@media only screen and (max-width: 1075px) {
  .title_text_container {
    padding: 0px 70px;
  }

  .title {
    font-size: 32px;
  }

  .title_separator {
    height: 5px;
  }

  .desc {
    font-size: 14px;
  }
}

@media only screen and (max-width: 775px) {
  .title_text_container {
    padding: 0px 55px;
  }
}

@media only screen and (max-width: 625px) {
  .title_image_container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
  }

  .title_text_container {
    height: calc(100vh - 110px);
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 60px;
    padding-bottom: 50px;
  }

  .image_gradient_overlay  {
    opacity: 1;
  }

  .title {
    color: #FFCB05;
  }

  .desc {
    color: #fff;
  }

  .title_image_container::after {
    box-shadow: 0px 0px 5px 5px #ffffff00; 
    animation: none;
  }

  .title_image_container {
    border-radius: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 65em) {
  .title_text_container, .title_image_container {
    height: calc(100vh - 90px);
  }
}

@media only screen and (max-width: 40em) {
  .title_text_container, .title_image_container {
    height: calc(100vh - 70px);
  }
}

@media only screen and (max-width: 500px) {
  .title_text_container {
    padding: 0px 40px;
    padding-bottom: 50px;
  }
  .title_separator {
    background: #34acff;
  }
}
