.section_desc{
  font-size: 1.05rem;
  color: #172F58;
  margin-top: 0px;
}


@media (max-width: 80em){
  .sidehead_section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .impactData {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
}

@media (max-width: 60em){
  .section_desc{
    font-size: 0.95rem;
  }
}

@media (max-width: 40em){
  .section_desc{
    font-size: 0.9rem;
  }
}

